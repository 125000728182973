import "../styles/normalize.min.css";
import "../styles/default.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Splash from "./Splash/Splash";
import MovieTable from "./MovieNight/MovieTable";
import WebsiteGameContainer from "./WebsiteGame/WebsiteGameContainer";
import SharonGoesToCharon from "./games/SharonGoesToCharon";

function App() {
  const fontAwesomeVersion = "4.7.0";
  const fontAwesomeRef = `https://cdnjs.cloudflare.com/ajax/libs/font-awesome/${fontAwesomeVersion}/css/font-awesome.min.css`;

  return (
    <Router>
      <div id="page">
        <link rel="stylesheet" href={fontAwesomeRef} />
        <Routes>
          <Route path="/movie-night" element={<MovieTable />} />
          <Route path="/website-game" element={<WebsiteGameContainer />} />
          <Route
            path="/games/sharon-goes-to-charon"
            element={<SharonGoesToCharon />}
          />
          <Route path="/" element={<Splash />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
