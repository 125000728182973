// Player.ts

import { clamp } from "../Utilities/Misc";
import { Shape, DirectionalCircle } from "./Shapes";
import { Coordinates, Direction } from "./Types";

export class Player {
  public readonly shape: Shape;
  private velocity: number = 0;
  private minSpeed: number; // -2/3 * maxSpeed
  private maxSpeed: number = 10;
  private acceleration: number = 0.5;
  private deceleration: number = 0.2;
  private driftFactor: number = 0.98;
  private _rotation: number = 0; // In radians
  // private turnSpeed: number = 0.07;
  private turnSpeed: number = 0.12;

  constructor(
    private _x: number,
    private _y: number,
    public readonly radius: number,
    public readonly color: string = "#00F",
    private collisionChecker: (shape: Shape) => boolean
  ) {
    this.shape = new DirectionalCircle(
      this._x,
      this._y,
      this.radius,
      this.color
    );
    this.minSpeed = (-2 / 3) * this.maxSpeed;
  }

  get x(): number {
    return this._x;
  }

  set x(value: number) {
    this._x = value;
    this.shape.x = value;
  }

  get y(): number {
    return this._y;
  }

  set y(value: number) {
    this._y = value;
    this.shape.y = value;
  }

  get rotation(): number {
    return this._rotation;
  }

  set rotation(value: number) {
    this._rotation = value;
    this.shape.rotation = value;
  }

  toString(): string {
    return `[${this.constructor.name} (${this._x}, ${this._y})]`;
  }

  updateTrajectory(direction: Direction): void {
    if (direction.y === 0) {
      this.velocity *= this.driftFactor;
    } else {
      this.velocity +=
        direction.y * (direction.y > 0 ? this.acceleration : this.deceleration);
    }
    this.velocity = clamp(this.minSpeed, this.velocity, this.maxSpeed);
    this.rotation += direction.x * this.turnSpeed;
  }

  move(direction: Direction): boolean {
    const original = {
      x: this.x,
      y: this.y,
    };
    this.updateTrajectory(direction);
    this.x += this.velocity * Math.cos(this.rotation);
    this.y += this.velocity * Math.sin(this.rotation);
    if (this.collisionChecker(this.shape)) {
      this.x = original.x;
      this.y = original.y;
      return this.moveIncremental();
    }
    return true;
  }

  moveIncremental(): boolean {
    const stepSize = 0.1;
    const totalSteps = Math.abs(this.velocity) / stepSize;
    const xAdjust =
      stepSize * Math.cos(this.rotation) * Math.sign(this.velocity);
    const yAdjust =
      stepSize * Math.sin(this.rotation) * Math.sign(this.velocity);
    for (let step = 0; step < totalSteps; step++) {
      if (!this.tryAdjustPos(xAdjust, yAdjust)) {
        return false;
      }
    }
    return true;
  }

  tryAdjustPos(xAdjust: number, yAdjust: number): boolean {
    const original = {
      x: this.x,
      y: this.y,
    };
    const target = {
      x: this.x + xAdjust,
      y: this.y + yAdjust,
    };
    // Full move
    this.x = target.x;
    this.y = target.y;
    if (!this.collisionChecker(this.shape)) return true;
    // Y adjust only
    this.x = original.x;
    this.y = target.y;
    if (!this.collisionChecker(this.shape)) return true;
    // X adjust only
    this.x = target.x;
    this.y = original.y;
    if (!this.collisionChecker(this.shape)) return true;
    // No move
    this.x = original.x;
    this.y = original.y;
    return false;
  }

  draw(
    ctx: CanvasRenderingContext2D,
    scale: number,
    offset: Coordinates
  ): void {
    this.shape.draw(ctx, scale, offset);
  }
}
