function ContactInfo() {
  const contact_info = [
    "DevOps Engineer",
    "Aurora, Colorado",
    "cameron.basham at gmail",
  ];
  return (
    <div id="header-contact-info" style={{ justifyContent: "center" }}>
      {contact_info.map((line, i) => (
        <h5
          key={i}
          style={{
            textAlign: "right",
            margin: "0 auto",
          }}
        >
          {line}
        </h5>
      ))}
    </div>
  );
}

function SplashHeaderMisc() {
  return (
    <div
      id="header-misc"
      style={{
        display: "grid",
        gridTemplateRows: "1fr 1fr",
        gridRowGap: "0.5em",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <h6
        id="header-site-under-construction"
        className="m-0 c-highlight2"
        style={{
          textAlign: "center",
        }}
      >
        site permanently
        <br />
        under construction
      </h6>
      <div id="header-quote-container" className="m-0-auto">
        <h6 id="header-quote" className="m-0">
          <i>We're gonna be alright.</i>
        </h6>
        {/* <!-- "Do you hear me, do you feel me? We gon' be alright." --> */}
      </div>
    </div>
  );
}

function SplashHeaderLink({ name, linkRef }) {
  const svgs = {
    linkedin: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 448 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#0077B5"
          d="M100.28 448H7.4V149.1h92.88zM53.79 108.1C24.09 108.1 0 83.43 0 54.27a53.74 53.74 0 0 1 53.79-53.79c29.66 0 53.79 24.66 53.79 54.27 0 29.16-24.13 53.65-53.79 53.65zM447.9 448h-92.48V302.4c0-34.7-12.6-58.4-44.1-58.4-24 0-38.2 16.1-44.5 31.6-2.3 5.6-2.9 13.4-2.9 21.3V448h-92.5V149.1h92.5v40.9h1.3c12.9-20.3 35.7-49.3 87.3-49.3 63.8 0 111.8 41.6 111.8 131.1V448z"
        />
      </svg>
    ),
    gitlab: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          fill="#FC6D26"
          points="133.2,277.2 256,512 0,277.2 63.3,86.8"
        />
        <polygon
          fill="#E24329"
          points="133.2,277.2 256,512 133.2,0 63.3,86.8"
        />
        <polygon
          fill="#FC6D26"
          points="378.8,277.2 256,512 512,277.2 448.7,86.8"
        />
        <polygon
          fill="#E24329"
          points="378.8,277.2 256,512 378.8,0 448.7,86.8"
        />
        <polygon
          fill="#FCA326"
          points="133.2,277.2 256,512 378.8,277.2 256,86.8"
        />
      </svg>
    ),
  };
  return (
    <div
      id={`${name}-container`}
      className="icon-container"
      style={{
        padding: "0",
      }}
    >
      <a href={`https://${linkRef}`}>
        {svgs[name]} {linkRef}
      </a>
    </div>
  );
}

function SplashHeaderLinks() {
  const links = [
    { name: "linkedin", linkRef: "linkedin.com/in/cpbasham" },
    { name: "gitlab", linkRef: "gitlab.com/clambordan" },
  ];
  return (
    <div
      id="header-links"
      style={{
        display: "grid",
        gridTemplateRows: "1fr 1fr",
        gridRowGap: "0.5em",
      }}
    >
      {links.map((link, i) => (
        <SplashHeaderLink key={i} {...link} />
      ))}
    </div>
  );
}

function SplashHeader() {
  return (
    <header id="header-container" className="bgc-ddd d-b">
      <h1 id="header-name" className="ta-c m-0 pt-025 pb-05">
        Cameron P. Basham
      </h1>
      <div id="header" className="d-g gtc-1-1-1 ai-c pb-1">
        <ContactInfo />
        <SplashHeaderMisc />
        <SplashHeaderLinks />
      </div>
    </header>
  );
}

export default SplashHeader;
