import { useEffect } from "react";

const SharonGoesToCharon = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/games/sharon-goes-to-charon/bundle.js`;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <noscript>
        Please enable JavaScript in your web browser to view this page.
      </noscript>
      <div id="pixi-content">
        <canvas id="pixi-canvas" style={{}} />
      </div>
    </div>
  );
};

export default SharonGoesToCharon;
