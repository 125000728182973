// WebsiteGameContainer.tsx

import React from "react";
import { CanvasProvider } from "../Utilities/CanvasProvider";
import WebsiteGame from "./WebsiteGame";
import "../../styles/website-game.scss";

const WebsiteGameContainer: React.FC = () => {
  return (
    <CanvasProvider>
      <WebsiteGame />
    </CanvasProvider>
  );
};

export default WebsiteGameContainer;
