// CanvasProvider.tsx

import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
  ReactNode,
} from "react";

interface CanvasContextProps {
  canvas: HTMLCanvasElement | null;
  ctx: CanvasRenderingContext2D | null;
}

const CanvasContext = createContext<CanvasContextProps | undefined>(undefined);

export const useCanvas = () => {
  const context = useContext(CanvasContext);
  if (!context) {
    throw new Error("useCanvas must be used within a CanvasProvider");
  }
  return context;
};

interface CanvasProviderProps {
  children: ReactNode;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
}

export const CanvasProvider: React.FC<CanvasProviderProps> = ({
  children,
  minWidth = 640,
  minHeight = 480,
  maxWidth = window.innerWidth,
  maxHeight = window.innerHeight,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    const handleResize = () => {
      if (canvas) {
        canvas.width = Math.min(
          maxWidth,
          Math.max(minWidth, window.innerWidth * 0.95)
        );
        canvas.height = Math.min(
          maxHeight,
          Math.max(minHeight, window.innerHeight * 0.95)
        );
        setCanvas(canvas);
      }
    };

    if (canvas) {
      const context = canvas.getContext("2d");
      setCanvas(canvas);
      setCtx(context);
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [minWidth, minHeight, maxWidth, maxHeight]);

  return (
    <CanvasContext.Provider value={{ canvas, ctx }}>
      <canvas
        ref={canvasRef}
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        tabIndex={0}
      />
      {children}
    </CanvasContext.Provider>
  );
};
