import "react-tabs/style/react-tabs.css";
import "../../styles/atomic.scss";
import "../../styles/splash.scss";
import SplashHeader from "./SplashHeader";
// import TerminalAndPages from "./TerminalAndPages";
import WebsiteGameContainer from "../WebsiteGame/WebsiteGameContainer";

function Splash() {
  return (
    <div id="splash" style={{}}>
      <SplashHeader />
      {/* <TerminalAndPages /> */}
      <WebsiteGameContainer />
    </div>
  );
}

export default Splash;
