// Track.ts

import { Rect, Shape } from "./Shapes";
import tracks from "./TrackData";
import { Coordinates } from "./Types";

export class Track {
  public readonly walls: Rect[];
  constructor(_width: number, _height: number) {
    const trackDimensions = [64, 40];
    const trackIndex = `m${trackDimensions[0]}x${trackDimensions[1]}`;
    const trackData = tracks[trackIndex] ? tracks[trackIndex] : [];
    const scale = 10;
    this.walls = trackData.map((wall: any) => {
      // if (wall["type"] === "rect" || true) {
      const { x, y, width, height, color } = wall;
      const horizontalBuffer = (_width - trackDimensions[0] * scale) / 2;
      const verticalBuffer = (_height - trackDimensions[1] * scale) / 2;
      return new Rect(
        x * scale + horizontalBuffer,
        y * scale + verticalBuffer,
        width * scale,
        height * scale,
        color
      );
      // }
    });
  }

  draw(ctx: CanvasRenderingContext2D, scale: number, offset: Coordinates) {
    this.walls.forEach((wall) => wall.draw(ctx, scale, offset));
  }

  checkCollisions(shape: Shape): boolean {
    // Return if any wall has any player checkpoint within it.
    const points = shape.collisionPoints();
    // if (!this.walls) return false;
    return this.walls.some((wall) =>
      points.some(({ x, y }) => wall.within(x, y))
    );
  }
}
