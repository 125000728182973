// Types.ts

export interface Drawable {
  draw(
    ctx: CanvasRenderingContext2D,
    scale?: number,
    offset?: Coordinates
  ): void;
}

export interface Dimensions {
  width: number;
  height: number;
  depth?: number;
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface Direction extends Coordinates {}

export interface KeyDirections {
  left: boolean;
  right: boolean;
  up: boolean;
  down: boolean;
}

export const directionString = (keys: KeyDirections) => {
  const { up, down, left, right } = keys;
  const vertical = up ? (down ? "" : "North") : down ? "South" : "";
  const horizontal = left ? (right ? "" : "West") : right ? "East" : "";
  return vertical && horizontal
    ? `${vertical}-${horizontal}`
    : `${vertical}${horizontal}`;
};

const b2i = (b: boolean) => (b ? 1 : 0);
export const directionCoordinates = (keys: KeyDirections): Direction => {
  const { up, down, left, right } = keys;
  const vertical = b2i(up) - b2i(down);
  const horizontal = b2i(right) - b2i(left);
  // return [horizontal, vertical];
  return { x: horizontal, y: vertical };
};
