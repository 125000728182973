// Shapes.ts

import { darken } from "./Colors";
import { Coordinates, Drawable } from "./Types";

// const radiansToDegrees = (radians: number) => {
//   return (radians * 180) / Math.PI;
// };

const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export abstract class Shape implements Drawable {
  public rotation: number = 0;
  constructor(public x: number, public y: number, public color: string) {}
  toString(): string {
    return `[${this.constructor.name} (${this.x}, ${this.y})]`;
  }
  abstract draw(
    ctx: CanvasRenderingContext2D,
    scale: number,
    offset: Coordinates
  ): void;
  abstract within(x: number, y: number): boolean;
  abstract outside(x: number, y: number): boolean;
  rotate(adjustment: number): void {
    this.rotation += adjustment;
  }

  abstract center(): { x: number; y: number };
  abstract left(): { x: number; y: number };
  abstract right(): { x: number; y: number };
  abstract top(): { x: number; y: number };
  abstract bottom(): { x: number; y: number };
  abstract topleft(): { x: number; y: number };
  abstract topright(): { x: number; y: number };
  abstract bottomleft(): { x: number; y: number };
  abstract bottomright(): { x: number; y: number };
  cardinalPoints(): { x: number; y: number }[] {
    return [this.top(), this.bottom(), this.left(), this.right()];
  }
  diagonalPoints(): { x: number; y: number }[] {
    return [
      this.topleft(),
      this.bottomleft(),
      this.topright(),
      this.bottomright(),
    ];
  }
  collisionPoints(): { x: number; y: number }[] {
    return [this.center(), ...this.cardinalPoints(), ...this.diagonalPoints()];
  }
}

export class Circle extends Shape {
  constructor(
    x: number,
    y: number,
    public radius: number,
    color: string = "#a00000"
  ) {
    super(x, y, color);
  }

  toString(): string {
    return `[${this.constructor.name} (${this.x}, ${this.y}), r: ${this.radius}]`;
  }

  within(x: number, y: number): boolean {
    const dx = x - this.x;
    const dy = y - this.y;
    return Math.sqrt(dx * dx + dy * dy) <= this.radius;
  }

  outside(x: number, y: number): boolean {
    return !this.within(x, y);
  }

  draw(ctx: CanvasRenderingContext2D, scale: number, offset: Coordinates) {
    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.arc(
      offset.x + scale * this.x,
      offset.y + scale * this.y,
      scale * this.radius,
      0,
      2 * Math.PI
    );
    ctx.fill();
  }

  center(): { x: number; y: number } {
    return { x: this.x, y: this.y };
  }
  top(): { x: number; y: number } {
    return { x: this.x, y: this.y - this.radius };
  }
  bottom(): { x: number; y: number } {
    return { x: this.x, y: this.y + this.radius };
  }
  right(): { x: number; y: number } {
    return { x: this.x + this.radius, y: this.y };
  }
  left(): { x: number; y: number } {
    return { x: this.x - this.radius, y: this.y };
  }
  topright(): { x: number; y: number } {
    const offset = this.radius * Math.SQRT1_2; // Radius * sqrt(2)/2
    return { x: this.x + offset, y: this.y - offset };
  }
  bottomright(): { x: number; y: number } {
    const offset = this.radius * Math.SQRT1_2; // Radius * sqrt(2)/2
    return { x: this.x + offset, y: this.y + offset };
  }
  bottomleft(): { x: number; y: number } {
    const offset = this.radius * Math.SQRT1_2; // Radius * sqrt(2)/2
    return { x: this.x - offset, y: this.y + offset };
  }
  topleft(): { x: number; y: number } {
    const offset = this.radius * Math.SQRT1_2; // Radius * sqrt(2)/2
    return { x: this.x - offset, y: this.y - offset };
  }
}

export class DirectionalCircle extends Circle {
  draw(ctx: CanvasRenderingContext2D, scale: number, offset: Coordinates) {
    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.arc(
      offset.x + scale * this.x,
      offset.y + scale * this.y,
      scale * this.radius,
      0,
      2 * Math.PI
    );
    ctx.fill();
    ctx.save();
    ctx.translate(offset.x + scale * this.x, offset.y + scale * this.y);
    ctx.rotate(this.rotation + degreesToRadians(90));
    ctx.fillStyle = darken(this.color, 100);
    ctx.fillRect(-1, 0, 2, -scale * this.radius);
    ctx.restore();
  }
}

export class Rect extends Shape {
  constructor(
    x: number,
    y: number,
    public width: number,
    public height: number,
    color: string = "#0000a0"
  ) {
    super(x, y, color);
  }

  toString(): string {
    return `[${this.constructor.name} (${this.x}, ${this.y}), (${
      this.x + this.width
    }, ${this.y + this.height})]`;
  }

  within(x: number, y: number): boolean {
    const is_within =
      x >= this.x &&
      x <= this.x + this.width &&
      y >= this.y &&
      y <= this.y + this.height;
    // if (is_within) {
    //   console.log(`(${x}, ${y}) is within ${this}`);
    // }
    // console.log(`(${x}, ${y}) is${is_within ? "" : " NOT"} within ${this}`);
    return is_within;
  }

  outside(x: number, y: number): boolean {
    return !this.within(x, y);
  }

  draw(ctx: CanvasRenderingContext2D, scale: number, offset: Coordinates) {
    ctx.fillStyle = this.color;
    ctx.fillRect(
      offset.x + scale * this.x,
      offset.y + scale * this.y,
      scale * this.width,
      scale * this.height
    );
  }

  center(): { x: number; y: number } {
    return { x: this.x + this.width / 2, y: this.y + this.height / 2 };
  }
  top(): { x: number; y: number } {
    return { x: this.x + this.width / 2, y: this.y };
  }
  bottom(): { x: number; y: number } {
    return { x: this.x + this.width / 2, y: this.y + this.height };
  }
  right(): { x: number; y: number } {
    return { x: this.x + this.width, y: this.y + this.height / 2 };
  }
  left(): { x: number; y: number } {
    return { x: this.x, y: this.y + this.height / 2 };
  }
  topright(): { x: number; y: number } {
    return { x: this.x + this.width, y: this.y };
  }
  bottomright(): { x: number; y: number } {
    return { x: this.x + this.width, y: this.y + this.height };
  }
  bottomleft(): { x: number; y: number } {
    return { x: this.x, y: this.y + this.height };
  }
  topleft(): { x: number; y: number } {
    return { x: this.x, y: this.y };
  }
}
