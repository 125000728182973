// Game.ts

import { Track } from "./Track";
import { Player } from "./Player";
import { Shape } from "./Shapes";
import { Coordinates, Dimensions, Direction } from "./Types";

const TARGET_FPS = 60; // TODO 30
const TARGET_FRAME_MS = 1000 / TARGET_FPS; // 1000 / 60 = 16.66666
export const gameDimensions: Dimensions = { width: 900, height: 680 };
let maze: Track;
let player: Player;

const playerCollisionChecker = (shape: Shape): boolean =>
  !shapeInGameBounds(shape) || maze.checkCollisions(shape);

export const create = () => {
  const { width, height } = gameDimensions;
  maze = new Track(width, height);
  player = new Player(100, 100, 15, "#F00", playerCollisionChecker);
};

export const destroy = () => {};

export const update = (deltaTime: number, direction: Direction) => {
  const timeAdjustment = deltaTime / TARGET_FRAME_MS;
  const move = {
    x: timeAdjustment * direction.x,
    y: timeAdjustment * direction.y,
  };
  player.move(move);
};

export const render = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  scale: number,
  offset: Coordinates
) => {
  // console.log(`Canvas dimensions: (${canvas.width}, ${canvas.height})`);
  // ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawBackground(ctx, canvas, scale, offset);
  maze.draw(ctx, scale, offset);
  player.draw(ctx, scale, offset);
};

const drawBackground = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  scale: number,
  offset: Coordinates
) => {
  ctx.fillStyle = "black";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "grey";
  ctx.fillRect(
    offset.x,
    offset.y,
    scale * gameDimensions.width,
    scale * gameDimensions.height
  );
  // const outlineWidth = 2;
  // ctx.fillStyle = "blue";
  // ctx.fillRect(0, 0, canvas.width, canvas.height);
};

export const shapeInGameBounds = (shape: Shape) => {
  const inBounds =
    shape.left().x >= 0 &&
    shape.top().y >= 0 &&
    shape.right().x <= gameDimensions.width &&
    shape.bottom().y <= gameDimensions.height;
  return inBounds;
};

export const pointInGameBounds = ({ x, y }: Coordinates) => {
  const inBounds =
    x >= 0 && y >= 0 && x <= gameDimensions.width && y <= gameDimensions.height;
  return inBounds;
};
