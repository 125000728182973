// TrackData.ts

type ShapeData = {
  type: string;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
};
type TrackData = ShapeData[];
type TrackDataMap = { [key: string]: TrackData };

const tracks: TrackDataMap = {
  m64x40: [
    { type: "rect", x: 0, y: 0, width: 64, height: 2, color: "#6c3525" }, // horizontal top
    { type: "rect", x: 63, y: 0, width: 2, height: 40, color: "#6c3525" }, // vertical right
    { type: "rect", x: 49, y: 14, width: 2, height: 40, color: "#6c3525" }, // vertical right 2
    { type: "rect", x: 35, y: 1, width: 2, height: 25, color: "#6c3525" }, // vertical center
    { type: "rect", x: 22, y: 36, width: 28, height: 2, color: "#6c3525" }, // horizontal bottom center
    // { type: "rect", x: 20, y: 26, width: 2, height: 24, color: "#6c3525" },
    // { type: "rect", x: 64, y: 15, width: 13, height: 2, color: "#6c3525" },
    {
      type: "rect",
      x: 21,
      y: 14,
      width: 2,
      height: 40,
      color: "#6c3525",
    }, // vertical left 2
    {
      type: "rect",
      x: 6,
      y: 1,
      width: 2,
      height: 40,
      color: "#6c3525",
    }, // vertical left
    { type: "rect", x: -2, y: 40, width: 10, height: 2, color: "#6c3525" }, // horizontal bottom left
    { type: "rect", x: -13, y: 25, width: 8, height: 2, color: "#6c3525" }, // horizontal center left
    { type: "rect", x: -6, y: 12, width: 5, height: 2, color: "#6c3525" }, // left obstacle
  ],
};

export default tracks;
