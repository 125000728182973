// Colors.ts

type RGB = { r: number; g: number; b: number };

function hexToRgb(hex: string): RGB {
  if (hex.length === 4) {
    return {
      r: parseInt(hex[1] + hex[1], 16),
      g: parseInt(hex[2] + hex[2], 16),
      b: parseInt(hex[3] + hex[3], 16),
    };
  } else if (hex.length === 7) {
    return {
      r: parseInt(hex.slice(1, 3), 16),
      g: parseInt(hex.slice(3, 5), 16),
      b: parseInt(hex.slice(5, 7), 16),
    };
  }
  throw new Error("Invalid hex color format");
}

function rgbToHex(r: number, g: number, b: number): string {
  return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
}

function parseColor(color: string, ctx?: CanvasRenderingContext2D): string {
  if (color.startsWith("#")) {
    return color;
  }

  if (ctx) {
    ctx.fillStyle = color;
    return ctx.fillStyle;
  }

  throw new Error(
    "Named color provided, but no canvas context available to parse it."
  );
}

function adjustColor(color: string, amount: number): string {
  const hex = parseColor(color);
  const { r, g, b } = hexToRgb(hex);
  const adjust = (value: number) => Math.min(255, Math.max(0, value + amount));
  const newR = adjust(r);
  const newG = adjust(g);
  const newB = adjust(b);
  return rgbToHex(newR, newG, newB);
}

export function lighten(color: string, amount: number = 0.2): string {
  return adjustColor(color, amount);
}

export function darken(color: string, amount: number = 0.2): string {
  return adjustColor(color, -amount);
}

export function distanceFromWhite(color: string): number {
  const hex = parseColor(color);
  const { r, g, b } = hexToRgb(hex);
  return Math.max(255 - r, 255 - g, 255 - b);
}

export function distanceFromBlack(color: string): number {
  const hex = parseColor(color);
  const { r, g, b } = hexToRgb(hex);
  return Math.max(r, g, b);
}
